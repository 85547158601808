import { Component, OnInit , Input} from '@angular/core';
import { Article } from '../article.model';
import { CartService } from '../../cart/cart.service';

@Component({
  selector: 'article-component',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {
    @Input() article: Article[];
    @Input() articlegroup: string;
    
    currentTime;
    currentDay;
    checkOpeningMittag = false;
    checkOpeningStandard = false;
 

  //constructor(private _cartService :Cart2Service,spezialitaet: Article[], spezialitaet ) {
    constructor(private cartService :CartService ) {
 
    
  }

  ngOnInit() {
    console.log("drinnen"); 
    var currentdate = new Date();
    this.currentTime = (this.addZero(currentdate.getHours())).toString() + (this.addZero(currentdate.getMinutes())).toString();
    this.currentDay = currentdate.getDay();
    console.log("drinnen1: " + this.currentTime);
    console.log("drinnen2: " + this.currentDay); 
    console.log("drinnen3a: " + this.checkOpeningStandard); 
    console.log("drinnen4a: " + this.checkOpeningMittag);   
    if((this.currentTime >1130) && (this.currentTime < 1500)){
      if( (this.currentDay == 0)  || (this.currentDay == 6)){ 
        this.checkOpeningMittag = false;
      }else{
        this.checkOpeningMittag = true;       
      }
      this.checkOpeningStandard = true;
    }
    if((this.currentTime >1730) && (this.currentTime < 2300)){
      this.checkOpeningStandard = true;

    }
    console.log("wochenende: " + this.currentDay);
    console.log("drinnen3: " + this.checkOpeningStandard); 
    console.log("drinnen4: " + this.checkOpeningMittag); 
    console.log('test: ' + (this.currentDay !== 0)  || (this.currentDay !== 6));
    //var datetime = "Last Sync: " + currentdate.getDay() + "/"+currentdate.getMonth() 
    //+ "/" + currentdate.getFullYear() + " @ " 
    //+ currentdate.getHours() + ":" 
    //+ currentdate.getMinutes() + ":" + currentdate.getSeconds();
  }

  addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
  }

  addToCart(article : Article, sub = -1) {
    article.added = true;
    let articleNew = Object.assign({}, article);
    console.log("sub: " + sub);
    if(sub != -1){
  
      articleNew.name = article.name + " (" + article.option[sub] + ")";
      articleNew.idInCart = article.idInCart + 1000 + sub;
    }
//    console.log('in cart: ' + JSON.stringify(article));
//console.log("i und option" + JSON.stringify(sub + ' ' + article.option[sub]));
//console.log("1" + JSON.stringify(article));
    this.cartService.addArticle(articleNew);
  }

  removeFromCart(article : Article) {
    //this.cartService.removeArticle(article);
    
  }
}
