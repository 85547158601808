import { Component, OnInit } from '@angular/core';

declare var ol: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  map: any;
  
  constructor() {}

  ngOnInit() {
    this.map = new ol.Map({

      target: 'map',

      layers: [

        new ol.layer.Tile({

          source: new ol.source.OSM()

        })

      ],

      view: new ol.View({

        center: ol.proj.fromLonLat([16.285610,48.080530 ]),

        zoom: 16

      })

    });
  }

}
