import { Injectable } from '@angular/core';
import { Article } from '../article/article.model'; 
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {
  private customer = {
    id : -1,
    name : '',
    phonenr : '',
    email : '',
    street : '',
    housenr : '',
    doornr : '',
    stairway : '',
    floor : '',
    district :  '',
    loggedIn : false
  };

  private customerSubject = new BehaviorSubject<any>({loaded: true, customer: this.customer});
  CustomerState = this.customerSubject.asObservable();

  constructor(private http: HttpClient, private router: Router) {}

  login(data):any {
  this.http.post("rest/customer.php",data).subscribe((data: any[]) => {
    console.log("service data: " + JSON.stringify(data));
    if(data.length != 0 && (data[0].id > 0)) {
       this.customer = {
         id : data[0].id,
         name : data[0].name,
         phonenr : data[0].phonenr,
         email : data[0].email,
         street : data[0].street,
         housenr : data[0].housenr,
         doornr : data[0].doornr,
         stairway : data[0].stairway,
         floor : data[0].floor,
         district :  data[0].district,
         loggedIn : true
     };
     //console.log("checkLogin2: " + JSON.stringify(data));
//     this.customer. = true;
     this.customerSubject.next({loaded: true, customer: this.customer});
     //return {"login":true,"customer":this.customer};
    }else{
      this.customerSubject.next({loaded: true, customer: this.customer}); 
      //return {"login":false,"customer":{}};    
    }
});
}

storeCustomer(customer){

  this.customer = customer;
  this.customerSubject.next({loaded: true, customer: customer});
}

logout() {
  this.customer = {
    id : -1,
    name : '',
    phonenr : '',
    email : '',
    street : '',
    housenr : '',
    doornr : '',
    stairway : '',
    floor : '',
    district :  '',
    loggedIn : false
  };

  this.customerSubject.next({loaded: false, customer: this.customer});
}

}