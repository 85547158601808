import { Component, OnInit } from '@angular/core';
//import { timer } from 'rxjs';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  //imgIndex = 0;
  //imgHeader = ['', 'assets/img/order.jpg', 'assets/img/buffet.jpg', 'assets/img/galerie.jpg'];
  //imgSrc = 'assets/img/order.jpg';

  constructor( ) { }

  ngOnInit() {
   /* let index = 0;
    let timer2 = timer(4000,4000);
    timer2.subscribe(t=>
      {

               
        this.imgSrc = this.imgHeader[index];
        console.log(this.imgHeader[index]);
        
        if(index == 3) {
          index = 1;
        }
      });
    */
  }

}
