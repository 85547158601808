import { Article } from './article.model';

export const sushi: Article[] = [
    {"id": 2200, //id
    "idInCart": 2200, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J15", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Sushi-Set klein", //name
    "description":"6 Sushi, 4 Maki",
    "ingredients":"B, D",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price": 7.50},
    {"id": 2201, //id
    "idInCart": 2201, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J16", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Sushi-Set mittel", //name
    "description":"8 Sushi, 4 Maki",
    "ingredients":"B, D",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":9.50},
    {"id": 2202, //id
    "idInCart": 2202, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J17", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Sushi-Set groß ", //name
    "description":"10 Sushi, 4 Maki",
    "ingredients":"B, D",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":11.50},
    {"id": 2203, //id
    "idInCart": 2203, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J18", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Sake-Set Lachs", //name
    "description":"8 Sushi, 4 Maki",
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":9.80},
    {"id": 2204, //id
    "idInCart": 2204, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J18a", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Sake-Set Lachs", //name
    "description":"10 Sushi, 4 Maki",
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":11.80},
    {"id": 2205, //id
    "idInCart": 2205, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J19", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Maguro-Set Thunfisch", //name
    "description":"8 Sushi, 4 Maki)",
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":10.50},
    {"id": 2206, //id
    "idInCart": 2206, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J20", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Sake Maguro Set", //name
    "description":"8 Sushi, 4 Maki)",
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":11.20}];