import { Article } from './article.model';

export const spezial2Personen: Article[] = [       
    {"id": 1400, //id
    "idInCart": 1400, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"Ma", //articleCode
    "articleGroup":"udon", //articleGroup
    "name":"Pikant säuerliche Suppe, gebackene Wan-Tanknusprige Gebacken mit Wu-Xiang Gemüsesauce, Früchtecocktail",
    "description":"", //description
    "ingredients":"A, F, C",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":23.90},
    {"id": 1401, //id
    "idInCart": 1401, //idInCart
    "numberInCart":0, //numberInCart/
    "added": false,
    "articleCode":"Mb", //articleCode
    "articleGroup":"udon", //articleGroup
    "name":"Pikant säuerliche Suppe Frühlingsrollen, Gong Bao Hühnerfleisch (pikant) Rindfleisch mit Broccoli, Früchtecocktail",
    "description":"", //description
    "ingredients":"A, F, C",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":23.90},
    {"id": 1401, //id
    "idInCart": 1401, //idInCart
    "numberInCart":0, //numberInCart/
    "added": false,
    "articleCode":"Mc", //articleCode
    "articleGroup":"udon", //articleGroup
    "name":"Huhn-Pilz-Bambus Suppe Minifrühlingsrollen, Knusprige Ente, Garnelen nach Sichuan Art , Früchtecocktail",
    "description":"", //description
    "ingredients":"A, F, C",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":26.90},
    {"id": 1402, //id
    "idInCart": 1402, //idInCart
    "numberInCart":0, //numberInCart/
    "added": false,
    "articleCode":"Md", //articleCode
    "articleGroup":"udon", //articleGroup
    "name":"Huhn-Pilz-Bambus Suppe Minifrühlingsrollen, Gebackene Garnelen, Lammfleisch nach Sichuan Art, Früchtecocktail",
    "description":"", //description
    "ingredients":"A, F, C",
    "newArticle":false,  
    "sharp":true, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":26.90}]; 
 