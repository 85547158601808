import { Article } from './article.model';

export const mittagsmenue: Article[] = [
    {"id": 3000, //id
    "idInCart": 3000, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"G1", //articleCode
    "articleGroup":"mittagsmenue", //articleGroup
    "name":"Gebratene Nudeln mit Hühnerfleisch und Gemüse", //description
    "description":"", //description
    "ingredients":"A, C, F",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":true, //optioptionsAvailableonsAvailable
    "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
    "optionName":0, //option
    "price":5.50},
    {"id": 3010, //id
    "idInCart": 3010, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"G2", //articleCode
    "articleGroup":"mittagsmenue", //articleGroup
    "name":"Rindfleisch nach Sichuan-Art, Reis", //description
    "description":"", //description
    "ingredients":"F",
    "newArticle":false,  
    "sharp":true, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":true, //optioptionsAvailableonsAvailable
    "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
    "optionName":0, //option
    "price":6.00},
     {"id": 3020, //id
    "idInCart": 3020, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"G3", //articleCode
    "articleGroup":"mittagsmenue", //articleGroup
    "name":"Hühnerfl. Gebacken in süß-sauer, Reis", //description
    "description":"", //description
    "ingredients":"A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":true, //optioptionsAvailableonsAvailable
    "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
    "optionName":0, //option
    "price":6.00},
    {"id": 3030, //id
   "idInCart": 3030, //idInCart
   "numberInCart":0, //numberInCart
   "added": false,
   "articleCode":"G4", //articleCode
   "articleGroup":"mittagsmenue", //articleGroup
   "name":"Schweinefleisch Sichuan-Art ,Reis", //description
   "description":"", //description
   "ingredients":"A",
   "newArticle":false,  
   "sharp":true, //sharp
   "vegetarian":false, //vegetarian
   "imageUrl":"", //imageUrl
   "optionsAvailable":true, //optioptionsAvailableonsAvailable
   "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
   "optionName":0, //option
   "price":6.00},
   {"id": 3040, //id
  "idInCart": 3040, //idInCart
  "numberInCart":0, //numberInCart
  "added": false,
  "articleCode":"G5", //articleCode
  "articleGroup":"mittagsmenue", //articleGroup
  "name":"Schweinefleisch süß-sauer, Reis", //description
  "description":"", //description
  "ingredients":"A",
  "newArticle":false,  
  "sharp":true, //sharp
  "vegetarian":false, //vegetarian
  "imageUrl":"", //imageUrl
  "optionsAvailable":true, //optioptionsAvailableonsAvailable
  "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
  "optionName":0, //option
  "price":6.00},
  {"id": 3050, //id
 "idInCart": 3050, //idInCart
 "numberInCart":0, //numberInCart
 "added": false,
 "articleCode":"G6", //articleCode
 "articleGroup":"mittagsmenue", //articleGroup
 "name":"Rindfleisch mit Zwiebeln, Reis", //description
 "description":"", //description
 "ingredients":"F",
 "newArticle":false,  
 "sharp":true, //sharp
 "vegetarian":false, //vegetarian
 "imageUrl":"", //imageUrl
 "optionsAvailable":true, //optioptionsAvailableonsAvailable
 "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
 "optionName":0, //option
 "price":6.00},
 {"id": 3060, //id
"idInCart": 3060, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G7", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Thai-Hühnerfleisch, Kokosmilch, Basilikum", //description
"description":"", //description
"ingredients":"G, A",
"newArticle":true,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"g7", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 3070, //id
"idInCart": 3070, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G8", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Gebr. Eierreis mit Hühnerfleisch", //description
"description":"", //description
"ingredients":"G, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":5.50},
{"id": 3080, //id
"idInCart": 3080, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G9", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Hühnerfleisch m. verschied. Gemüse, Reis", //description
"description":"", //description
"ingredients":"G, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 3090, //id
"idInCart": 3090, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G10", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Acht Schätze, Reis", //description
"description":"", //description
"ingredients":"E, F",
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 30100, //id
"idInCart": 30100, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G11", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Schweinefleisch gebacken in süß-sauer-scharfer Sauce ,Reis", //description
"description":"", //description
"ingredients":"A, F",
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //
"optionName":0, //option
"price":6.00},
{"id": 30200, //id
"idInCart": 30200, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G12", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Gebackene Tintenfischringe mit süß-saurer Sauce, Reis", //description
"description":"g12", //description
"ingredients":"D, A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 30300, //id
"idInCart": 30300, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G13", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Knuspriges Huhn mit Gemüse, Reis", //description
"description":"", //description
"ingredients":"A, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 30013, //id
"idInCart": 30013, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G14", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Knusprige Ente mit Gemüse, Reis", //description
"description":"", //description
"ingredients":"A, F",
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 30014, //id
"idInCart": 30014, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G15", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Gebratenes Mischgemüse, Reis", //description
"description":"", //description
"ingredients":"A,F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":5.50},
    {"id": 30015, //id
   "idInCart": 30015, //idInCart
   "numberInCart":0, //numberInCart
   "added": false,
   "articleCode":"G16", //articleCode
   "articleGroup":"mittagsmenue", //articleGroup
   "name":"Hühnerfleisch Sichuan-Art ,Reis", //description
   "description":"", //description
   "ingredients":"A, F",
   "newArticle":false,  
   "sharp":true, //sharp
   "vegetarian":false, //vegetarian
   "imageUrl":"", //imageUrl
   "optionsAvailable":true, //optioptionsAvailableonsAvailable
   "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
   "optionName":0, //option
   "price":6.00},
   {"id": 30016, //id
  "idInCart": 30016, //idInCart
  "numberInCart":0, //numberInCart
  "added": false,
  "articleCode":"G17", //articleCode
  "articleGroup":"mittagsmenue", //articleGroup
  "name":"Thai-Rindfleisch, Kokosmilch u. Basilikum", //description
  "description":"", //description
  "ingredients":"A,D,G",
  "newArticle":true,  
  "sharp":true, //sharp
  "vegetarian":false, //vegetarian
  "imageUrl":"", //imageUrl
  "optionsAvailable":true, //optioptionsAvailableonsAvailable
  "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
  "optionName":0, //option
  "price":6.00},
  {"id": 30017, //id
 "idInCart": 30017, //idInCart
 "numberInCart":0, //numberInCart
 "added": false,
 "articleCode":"G18", //articleCode
 "articleGroup":"mittagsmenue", //articleGroup
 "name":"Hühnerfleisch mit Curry, Reis", //description
 "description":"", //description
 "ingredients":"C.F",
 "newArticle":false,  
 "sharp":false, //sharp
 "vegetarian":false, //vegetarian
 "imageUrl":"", //imageUrl
 "optionsAvailable":true, //optioptionsAvailableonsAvailable
 "option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
 "optionName":0, //option
 "price":6.00},
 {"id": 30018, //id
"idInCart": 30018, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G19", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Dou-Fu mit Hühnerfleisch und Gemüse,Reis", //description
"description":"", //description
"ingredients":"A, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 30019, //id
"idInCart": 30019, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G20", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Knusprige Ente scharf ,Reis", //description
"description":"", //description
"ingredients":"A, F",
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 30020, //id
"idInCart": 30020, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G21", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Knusprige Ente mit Zwiebeln, ,Reis", //description
"description":"", //description
"ingredients":"A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 30021, //id
"idInCart": 30021, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G22", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Knuspriges Huhn mit Zwiebeln,,Reis", //description
"description":"", //description
"ingredients":"A, F",
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00},
{"id": 30022, //id
"idInCart": 30022, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G23", //articleCode
"articleGroup":"mittagsmenue", //articleGroup
"name":"Knusprige Ente süß-sauer,,Reis", //description
"description":"", //description
"ingredients":"A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":true, //optioptionsAvailableonsAvailable
"option":["Mini-Frühlingsrolle ", "Frühlingsrolle ", "Pikant-Säuerliche Suppe "], //option 
"optionName":0, //option
"price":6.00}];
