import { Article } from './article.model';

export const bento: Article[] = [
    {"id": 1100, //id
    "idInCart": 1100, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J21", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Toriyaki Bento", //description
"description":"Suppe, Salat, 4 Sushi,6 Maki, Minifrühlingsrolle, gegrillte Hühnerkeule, Reis, Nachtisch", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharpudon
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50},
{"id": 1101, //id
"idInCart": 1101, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J22", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Sake Bento", //description
"description":" Suppe, Salat, 4 Sushi, 6 Maki, Minifrühlingsrolle, gegrillter Lachs, Reis, Nachtisch", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50},
{"id": 1102, //id
"idInCart": 1102, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J23", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Maguro Bento ", //description
"description":"Suppe, Salat, 4 Sushi 6 Maki, Minifrühlingsrolle, gegrillter Thunfisch, Reis, Nachtisch", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50},
{"id": 1103, //id
"idInCart": 1103, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J24", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Miso Yaki Bento ", //description
"description":"Suppe, Salat, 4 Sushi 6 Maki, Minifrühlingsrolle, gegrillter Butterfisch Reis, Nachtisch", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50},
{"id": 1104, //id
"idInCart": 1104, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J25", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Agamo Bento ", //description
"description":"Suppe, Salat, 4 Sushi 6 Maki, Minifrühlingsrolle,knusprige Ente Reis, Nachtisch", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50},
{"id": 1105, //id
"idInCart": 1105, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J26", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Sushi Sashimi Bento ", //description
"description":"Suppe, Salat, 4 Sushi 6 Maki, Sashimi und Nachtisch, Minifrühlingsrolle", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50},
{"id": 1106, //id
"idInCart": 1106, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J27", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Bulgogi Bento ", //description
"description":"Suppe, Salat, 4 Sushi 6 Maki, Minifrühlingsrolle, gebratenes Rindfleisch Reis, Nachtisch", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50},
{"id": 1107, //id
"idInCart": 1107, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J28", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Maki Bento", //description
"description":"verschiedene Maki", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50},
{"id": 1108, //id
"idInCart": 1108, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J29", //articleCode
"articleGroup":"udon", //articleGroup
"name":"Sushi Bento", //description
"description":"verschiedene Sushi und Maki", //description
"ingredients":"A, B, D, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price": 10.50}];
