import {Component, OnInit, TemplateRef, ViewChild, Inject} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { CartComponent } from '../cart/cart/cart.component';
import { CartService } from '../cart/cart.service';
import { CustomerService } from '../customer/customer.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})


export class MenuComponent implements OnInit {
  cart: any[] = [];
  amountOrder: number = 0;
  customer: {};
  loggedIn: false;
  urlHome;
  ticks =0;

  private subscriptionCart : Subscription;
  private subscriptionCustomer : Subscription;
  constructor(private router: Router, private customerService: CustomerService, private cartService: CartService,  private bottomSheet: MatBottomSheet) {}

  ngOnInit() {
    this.urlHome = (this.router.url == '/home') ? false : true;
    //console.log((this.router.url == '/' || this.router.url));

   // this.mode = this.route.snapshot.paramMap.get('mode');
    this.subscriptionCart = this.cartService.CartState.subscribe((state) => {
      //console.log("Cart-Menue: state.amountItemsOrder: " + JSON.stringify(state.cart.amountItemsOrder));
      this.amountOrder = state.cart.amountItemsOrder;
    });
    this.subscriptionCustomer = this.customerService.CustomerState.subscribe((state) => {
      //console.log("State customerr: " + JSON.stringify(state.customer));
      this.customer = state.customer;
      this.loggedIn = state.customer.loggedIn;
    });

 
  }



  openBottomSheet(): void {
 //this.cart = this.cartService.getCart();
//alert('222' + JSON.serialize(this.cartService.getCart()));
  this.bottomSheet.open(CartComponent, {
//    data: this.cartService.getCart(),
  });
  }

 
  close(event: MouseEvent): void {
  //  this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}

