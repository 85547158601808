import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material';import {} from '@angular/material';
import { CartService } from '../cart.service';
import { Article } from '../../article/article.model'; 
import { Subscription } from 'rxjs';
import { CustomerService } from '../../customer/customer.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})

export class CartComponent implements OnInit {
  loaded : boolean = true;

  private subscription : Subscription;

  cartItems : Article[];
  amountItemsOrder: number;
  sumOrder: number;

  private subscriptionCustomer: Subscription;
  customer = {id:-1,
    "name":'',
    "email":'',
    "password":'',        
    "phonenr":'',
    "street":'',
    "housenr":'',
    "doornr":'',
    "stairway":'',
    "floor":'',
    "district":''
};

  constructor(private router:  Router, public customerService: CustomerService, public cartService: CartService, private bottomSheetRef: MatBottomSheetRef<any>,   @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    this.subscription = this.cartService.CartState.subscribe((state) => {
      //console.log("cart this.subscription" + JSON.stringify(state));
      //console.log("cart state.amountItemsOrder: " + JSON.stringify(state.cart.amountItemsOrder));
      this.cartItems = state.cart.articles;
      this.amountItemsOrder = state.cart.amountItemsOrder;
      this.sumOrder = state.cart.sumOrder;
    });


    this.subscriptionCustomer = this.customerService.CustomerState.subscribe((state) => {
      //console.log("cart this.subscription" + JSON.stringify(state));
      //console.log("cart state.amountItemsOrder: " + JSON.stringify(state.cart.amountItemsOrder));
      this.customer = state.customer;

    });
    //this.cartItems = this.cartService.getCart();
   // console.log('cart: ' +  this.cartItems);
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    //event.preventDefault();
  }

  addToCart(article,sub = -1) {

   this.cartService.addArticle(article);
  }

  removeFromCart(article) {
    //console.log('sub');
  this.cartService.removeArticle(article);
  }

  goToCheckout(target){
    this.bottomSheetRef.dismiss();
   // event.preventDefault();
   // this.router.navigate(['checkout', 'cart']);
   this.router.navigate([target]);
  }

}


