import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './static/home/home.component';
import { BuffetComponent } from './static/buffet/buffet.component';
import { GalleryComponent } from './static/gallery/gallery.component';
import { ContactComponent } from './static/contact/contact.component';
import { MenuComponent } from './menu/menu.component';
import { ArticleComponent } from './article/article/article.component';
import { ArticleListComponent } from './/article/article-list/article-list.component';
import { CartComponent } from './cart/cart/cart.component';

import {MatButtonModule, MatCheckboxModule} from '@angular/material';

import {FlexLayoutModule} from "@angular/flex-layout";
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatInputModule} from '@angular/material';
import { MatSelectModule } from '@angular/material';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { LightboxModule } from 'ngx-lightbox';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './checkout/checkout.component';

import { HttpClientModule } from '@angular/common/http';
import {MatBadgeModule} from '@angular/material/badge';
import { LastorderstepComponent } from './static/lastorderstep/lastorderstep.component';
import { LoginComponent } from './customer/login/login.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BuffetComponent,
    GalleryComponent,
    ContactComponent,
    MenuComponent,
    ArticleComponent,
    ArticleListComponent,
    CartComponent,
    CheckoutComponent,
    LastorderstepComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule, 
    MatCheckboxModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule,
    MatBottomSheetModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatExpansionModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    LightboxModule,
    HttpClientModule,
    MatBadgeModule,
    RouterModule.forRoot([
      {path: 'checkout', component: CheckoutComponent },
      {path: 'home', component: HomeComponent},
      {path: 'buffet', component: BuffetComponent},
      {path: 'gallery', component: GalleryComponent},
      {path: 'articlelist', component: ArticleListComponent},
      {path: 'login', component: LoginComponent },
      { path: '', redirectTo: '/home',pathMatch: 'full'},
      {path: 'lastorderstep', component: LastorderstepComponent },
      {path: 'contact', component: ContactComponent}          
  ])
  ],
 // providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
 providers: [],
  entryComponents: [
      CartComponent
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
