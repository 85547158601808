import { Component, OnInit, ViewChild  } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { CartService } from '../cart/cart.service';
import { CustomerService } from '../customer/customer.service';
import { Article } from '../article/article.model'; 
import { Subscription } from 'rxjs';
import { animate, state, transition, trigger, style, keyframes } from '@angular/animations';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers':'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    'Authorization':'authkey',
    'userid':'1'
  })
};

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
  animations:[trigger('myInsertRemoveTrigger', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('5s', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      animate('5s', style({ opacity: 0 }))
    ])
  ])
]
})
export class CheckoutComponent implements OnInit {
  //@ViewChild('stepper') stepper;
  articles : Article[];
  checkoutControlGroup: FormGroup ;

  loaded : boolean = true;
  private subscription : Subscription;
  private subscriptionCustomer : Subscription;
  cartItems : Article[];
  amountItemsOrder: number;
  sumOrder: number;

  errorLogin = 0;

  color:string = 'grey';
  
  login;
  mode: string;//cart or customer
  customerDataEdit: boolean = true;
  customer = {id:-1,
    "name":'',
    "email":'',
    "password":'',        
    "phonenr":'',
    "street":'',
    "housenr":'',
    "doornr":'',
    "stairway":'',
    "floor":'',
    "district":''
};
  
  constructor(private  customerService: CustomerService, private router: Router, public cartService: CartService,private http: HttpClient, private route: ActivatedRoute, private location: Location) {
   }

  ngOnInit() {

    //console.log("this.loggedIn: " + JSON.stringify(this.loggedIn));
    this.articles = [];

    this.checkoutControlGroup = new FormGroup({
      notice: new FormControl(''),
      payment: new FormControl('in Bar'),
    });
 

    this.subscription = this.cartService.CartState.subscribe((state) => {
      //console.log("cart this.subscription" + JSON.stringify(state));
      //console.log("cart state.amountItemsOrder: " + JSON.stringify(state.cart.amountItemsOrder));
      this.cartItems = state.cart.articles;
      this.amountItemsOrder = state.cart.amountItemsOrder;
      this.sumOrder = state.cart.sumOrder;
    });


 
    this.subscriptionCustomer = this.customerService.CustomerState.subscribe((state) => {
      //console.log("cart this.subscription" + JSON.stringify(state));
      //console.log("cart state.amountItemsOrder: " + JSON.stringify(state.cart.amountItemsOrder));
      this.customer = state.customer;

    });

    this.subscriptionCustomer = this.customerService.CustomerState.subscribe((state) => {
      this.customer = state.customer;
    });

    //this.mode = this.route.snapshot.paramMap.get('mode');
   // console.log("this.loginGroup: " + JSON.stringify(this.loginGroup));
  }
  


  changeStyle($event){
    this.color = $event.type == 'mouseover' ? 'black' : 'grey';
  }

  toggleViewMode(){
   this.customerDataEdit = true;

  }

  getErrorMessage() {
   // return this.loginGroup.emailLogin.hasError('required') ? 'Bitte eine gültige email-Adresse eingeben' :
   //     this.loginGroup.emailLogin.hasError('email') ? 'Das ist leider keine gültige email-Adresse' :
   //         '';
  }

  

  addToCart(article, sub = -1) {
    if(sub != -1){
      article.name = article.name + " " + article.option[sub];
      article.idInCart = article.idInCart + 1000 + sub;
    }
   this.cartService.addArticle(article);
  }

  removeFromCart(article) {
  this.cartService.removeArticle(article);
  }
/*
  sendCustomerData(){
    var myPostObject = {
      "typ":"customerData",
      "customer":{
        "name":this.customerGroup.value.name,
        "email":this.customerGroup.value.email,
        "password":this.customerGroup.value.password,        
        "phonenr":this.customerGroup.value.phonenr,
        "street":this.customerGroup.value.street,
        "housenr":this.customerGroup.value.housenr,
        "doornr":this.customerGroup.value.doornr,
        "stairway":this.customerGroup.value.stairway,
        "floor":this.customerGroup.value.floor,
        "district":this.customerGroup.value.district
      }
    };
    //console.log("postdata: " + JSON.stringify(myPostObject));
    this.http.post("rest/customer.php",myPostObject).subscribe((data: any) => {

      this.customer.name = this.customerGroup.value.name;
      this.customer.email = this.customerGroup.value.email;      
      this.customer.phonenr = this.customerGroup.value.phonenr;
      this.customer.street = this.customerGroup.value.street;
      this.customer.housenr = this.customerGroup.value.housenr;
      this.customer.doornr = this.customerGroup.value.doornr;
      this.customer.stairway = this.customerGroup.value.stairway;
      this.customer.floor = this.customerGroup.value.floor;
      this.customer.district = this.customerGroup.value.district;
      // 
    });
  }
*/
  sendOrder(){
    
    var myPostObject = {
      "typ":"order",
      "customer":{
        "name":this.customer.name,
        "email":this.customer.email,
        "phonenr":this.customer.phonenr,
        "street":this.customer.street,
        "housenr":this.customer.housenr,
        "doornr":this.customer.doornr,
        "stairway":this.customer.stairway,
        "floor":this.customer.floor,
        "district":this.customer.district
      },
      "cart":this.cartItems,
      "sumOrder":this.sumOrder,
      "notice":this.checkoutControlGroup.value.notice,
      "payment":this.checkoutControlGroup.value.payment
    };
  
    console.log("postdatafinal: " + JSON.stringify(myPostObject));
    
   // this.http.post("rest/neworder.php",myPostObject).subscribe((data) => console.log("data: " + JSON.stringify(data)));
   this.http.post("rest/neworder.php",myPostObject).subscribe((data) => {this.cartService.deleteCart();this.router.navigateByUrl('/lastorderstep');console.log("data: " + JSON.stringify(data))});
  }


 

}
