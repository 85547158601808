import { Component, OnInit, ViewChild  } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { CustomerService } from '../customer.service';
import { Subscription } from 'rxjs';
import { animate, state, transition, trigger, style, keyframes } from '@angular/animations';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers':'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    'Authorization':'authkey',
    'userid':'1'
  })
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations:[trigger('visibilityChanged', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('5s', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      animate('5s', style({ opacity: 0 }))
    ])
  ])
]
})

export class LoginComponent implements OnInit {
  mode='login';
  loginGroup: FormGroup ;
  newPassRequestGroup: FormGroup ;
  customerGroup: FormGroup ;
  requestNewPasswordGroup: FormGroup ;
  color:string = 'grey';

  private subscriptionCustomer: Subscription;
  customer;
  login = false;

  modeError = '';
  modeCheckLogin = false;//standardwert...zeigt an,das gerade versucht wird,sich einzuloggen
  hide = true;

  constructor(private  customerService: CustomerService,private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.loginGroup = new FormGroup({
      emailLogin: new FormControl('',[Validators.required, Validators.email]),
      passwordLogin: new FormControl('',[
        Validators.required
      ]),
      checkLogin: new FormControl('')
    });

    this.requestNewPasswordGroup = new FormGroup({
      emailRequest: new FormControl('',[Validators.required, Validators.email]),
      passwordNew: new FormControl('',[
        Validators.required
      ]),
      codeRequest: new FormControl('',[Validators.required]),
    });



    this.customerGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      phonenr: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      email: new FormControl('',[Validators.required, Validators.email]),
      street: new FormControl('', Validators.required),
      housenr: new FormControl('', Validators.required),
      doornr: new FormControl(''),
      stairway: new FormControl(''),
      floor: new FormControl(''),
      district: new FormControl('', Validators.required)
    });



 



    this.subscriptionCustomer = this.customerService.CustomerState.subscribe((state) => {
      console.log("state.customer --- last" + JSON.stringify(state));
      //console.log("cart state.amountItemsOrder: " + JSON.stringify(state.cart.amountItemsOrder));
      if((state.customer.id == -1) && (this.modeCheckLogin)){
        this.modeError = "loginfail";
        //console.log('this.modeError && state.customer == -1:' + this.modeError);
      }else if((state.customer.id > 0) && (this.modeCheckLogin)){
        this.customer = state.customer;
        this.modeError = "";
        this.mode = "customerData";
       // console.log('state.customer != -1:' + this.modeError);

      }
      else if((state.customer.id > 0)){
        this.customer = state.customer;
        this.modeError = "";
        this.mode = "customerData";
       // console.log('state.customer != -1:' + this.modeError);

      }
    });
  
  }


  changeStyle($event){
    this.color = $event.type == 'mouseover' ? 'black' : 'grey';
  }

  loginCheck(){
    this.modeCheckLogin = true;
    const data = { "type":"login","emailLogin": this.loginGroup.value.emailLogin, "passwordLogin": this.loginGroup.value.passwordLogin};
    this.customerService.login(data);
     //console.log('this.customerService.login(data)' + JSON.stringify(success));
    //if (success.login) {
    //  this.modeError='loginFail'; 
    //}
   
  }

  newpassword1(event: MouseEvent): void {
    this.modeCheckLogin = false;
    this.mode='requestnewpassword';
    event.preventDefault();
  }

  edit(event: MouseEvent): void {
    this.modeCheckLogin = false;
    this.mode='costumerData';

    event.preventDefault();
  }

  editCustomerData(event: MouseEvent): void{
    this.mode='costumerDataEdit';
   // this.customerGroup.controls['name'].setValue('mhjmhjm');
  
    //this.customerGroup.controls.doornr.patchValue('aaajhj');
 
    //this.customerGroup.controls['doornr'].patchValue('asas');

    this.customerGroup.patchValue({
      "name": this.customer.name,
      "phonenr": this.customer.phonenr,
      "password": '', 
        "email": this.customer.email,
       "street": this.customer.street,
        "housenr": this.customer.housenr,
        "doornr": this.customer.doornr,
        "stairway": this.customer.stairway,
        "floor": this.customer.floor,
        "district": this.customer.district,
    });
    
   event.preventDefault();
  }

  getErrorMessage() {
    return  'Nicht gültig' ;
  }


  sendCustomerDataEdit(mode){
    console.log(JSON.stringify('mode:' + mode));
    var myPostObject = {
      "typ":mode,
      "customer":{
        "name":this.customerGroup.value.name,
        "email":this.customerGroup.value.email,
        "password":this.customerGroup.value.password,        
        "phonenr":this.customerGroup.value.phonenr,
        "street":this.customerGroup.value.street,
        "housenr":this.customerGroup.value.housenr,
        "doornr":this.customerGroup.value.doornr,
        "stairway":this.customerGroup.value.stairway,
        "floor":this.customerGroup.value.floor,
        "district":this.customerGroup.value.district
      }
    };
    //console.log("postdata: " + JSON.stringify(myPostObject));
    this.http.post("rest/customer.php",myPostObject).subscribe((data: any) => {
      
   
      this.customer = {id:data[0].id,
        name:data[0].name,
        email:data[0].email,
        phonenr:data[0].phonenr,
        street:data[0].street,
        housenr:data[0].housenr,
        doorn:data[0].doorn,
        stairway:data[0].stairway,
        floor:data[0].floor,
        district: data[0].district,
        loggedIn : false

      }

      
     this.customerService.storeCustomer({id:data[0].id,
      name:data[0].name,
      email:data[0].email,
      phonenr:data[0].phonenr,
      street:data[0].street,
      housenr:data[0].housenr,
      doorn:data[0].doorn,
      stairway:data[0].stairway,
      floor:data[0].floor,
      district: data[0].district,
      loggedIn : false
    });
      this.router.navigateByUrl('/checkout')
    
    });
  }

  sendCustomerData(){
    var myPostObject = {
      "typ":"customerData",
      "customer":{
        "name":this.customerGroup.value.name,
        "email":this.customerGroup.value.email,
        "password":this.customerGroup.value.password,        
        "phonenr":this.customerGroup.value.phonenr,
        "street":this.customerGroup.value.street,
        "housenr":this.customerGroup.value.housenr,
        "doornr":this.customerGroup.value.doornr,
        "stairway":this.customerGroup.value.stairway,
        "floor":this.customerGroup.value.floor,
        "district":this.customerGroup.value.district
      }
    };
    //console.log("postdata: " + JSON.stringify(myPostObject));
    this.http.post("rest/customer.php",myPostObject).subscribe((data: any) => {
      
   
      this.customer = {id:data[0].id,
        name:data[0].name,
        email:data[0].email,
        phonenr:data[0].phonenr,
        street:data[0].street,
        housenr:data[0].housenr,
        doorn:data[0].doorn,
        stairway:data[0].stairway,
        floor:data[0].floor,
        district: data[0].district,
        loggedIn : false

      }

      
     this.customerService.storeCustomer({id:data[0].id,
      name:data[0].name,
      email:data[0].email,
      phonenr:data[0].phonenr,
      street:data[0].street,
      housenr:data[0].housenr,
      doorn:data[0].doorn,
      stairway:data[0].stairway,
      floor:data[0].floor,
      district: data[0].district,
      loggedIn : false
    });
      this.router.navigateByUrl('/checkout')
    
    });
  }

}
