import { Article } from './article.model';

export const maki: Article[] = [
    {"id": 1200, //id
    "idInCart": 1200, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J1/a", //articleCode
    "articleGroup":"maki", //articleGroup
    "name":"Sake Maki (Lachs) 12 Stk.",
    "description":"", //description
    "ingredients":"D, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price": 5.50},
    {"id": 1201, //id
    "idInCart": 1201, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J1/a", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Sake Maki (Lachs) 18 Stk.",
    "description":"", //description
    "ingredients":"D, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price": 7.50},
    {"id": 1202, //id
    "idInCart": 1202, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J2/a", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Avokado Maki 12 Stk.",
    "description":"", //description
    "ingredients":"N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price": 6.50},
    {"id": 1203, //id
    "idInCart": 1203, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J2/a", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Avokado Maki 18 Stk.",
    "description":"", //description
    "ingredients":"N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price": 8.50},
    {"id": 1204, //id
    "idInCart": 1204, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J3/a", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Teka Maki 12 Stk.",
    "description":"", //description
    "ingredients":"D, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price": 6.50},
    {"id": 1205, //id
    "idInCart": 1205, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J3/a", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Teka Maki 18 Stk.",
    "description":"", //description
    "ingredients":"D, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price": 8.50},
    {"id": 1206, //id
    "idInCart": 1206, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J4/a", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Tempura Maki 12 Stk.",
    "description":"Maki mit frittierten Schrimps", //description
    "ingredients":"B, A, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price": 7.20},
    {"id": 1207, //id
    "idInCart": 1207, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J4/a", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Tempura Maki 18 Stk.",
    "description":"Maki mit frittierten Schrimps", //description
    "ingredients":"B, A, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price": 9.00},
        {"id": 1208, //id
        "idInCart": 1208, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J5/a", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Ebi Maki 12 Stk.",
        "description":"", //description
        "ingredients":"B, N ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 7.20},      
        {"id": 1209, //id
        "idInCart": 1209, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J5/a", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Ebi Maki  18 Stk.",
        "description":"", //description
        "ingredients":"B, N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 9.00},
        {"id": 1210, //id
        "idInCart": 1210, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J6/a", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Kani Maki  12 Stk.",
        "description":"", //description
        "ingredients":"B, N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 5.50},        
        {"id": 1211, //id
        "idInCart": 1211, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J6/a", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Kani Maki  18 Stk.",
        "description":"", //description
        "ingredients":"B, N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 7.50},
        {"id": 1212, //id
        "idInCart": 1212, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J7", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Kani Temaki ",
        "description":"", //description
        "ingredients":"B, N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 2.00},
        {"id": 1213, //id
        "idInCart": 1213, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J8", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Sake Temaki ",
        "description":"", //description
        "ingredients":"D, N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 2.50},
        {"id": 1214, //id
        "idInCart": 1214, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J9", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Maki Mix (3 Sorten)  18 Stk.",
        "description":"", //description
        "ingredients":"D, N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 8.50},
        {"id": 1215, //id
        "idInCart": 1215, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J10", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"California Maki  12 Stk.",
        "description":"", //description
        "ingredients":"D, N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 8.50},    
        {"id": 1216, //id
        "idInCart": 1216, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J11", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Futo Maki  12 Stk.",
        "description":"", //description
        "ingredients":"N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 8.50},
        {"id": 1217, //id
        "idInCart": 1217, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J12/a", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Kappa Maki  12 Stk.",
        "description":"", //description
        "ingredients":"N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 5.10},
        {"id": 1218, //id
        "idInCart": 1218, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J12/a", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Kappa Maki 18 Stk.",
        "description":"", //description
        "ingredients":" N",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 7.00},
        {"id": 1219, //id
        "idInCart": 1219, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J13", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Sashimi klein  (15 Scheiben)",
        "description":"", //description
        "ingredients":"D",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 12.90},
        {"id": 1220, //id
        "idInCart": 1220, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J14", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"Sashimi groß  (20 Scheiben)",
        "description":"", //description
        "ingredients":"D",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price": 16.90}];