import { Article } from './article.model';

export const getraenke: Article[] = [
    {"id": 2401, //id
    "idInCart": 2401, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Coca Cola 	0,5 l Fl.", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.20},
    {"id": 2402, //id
    "idInCart": 2402, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Cola Light	0,5 l Fl.", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.20},
    {"id": 2403, //id
    "idInCart": 2403, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Fanta 	0,5 l Fl.", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.20},
    {"id": 2404, //id
    "idInCart": 2404, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Sprite 	0,5 l Fl.", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.20},
    {"id": 2405, //id
    "idInCart": 2405, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Eistee Zitrone (Rauch)	 0,5 l", //name
    "description":"", //description
    "ingredients":" ",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.00},
    {"id": 2406, //id
    "idInCart": 2406, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Eistee Pfirsich (Rauch) 	 0,5 l", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.00},
    {"id": 2407, //id
    "idInCart": 2407, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Lycheesaft 	0,25l Dose", //name
    "description":"", //description
    "ingredients":" ",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.60},
    {"id": 2408, //id
    "idInCart": 2408, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Mangosaft 	0,25l Dose", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.60},
    {"id": 2409, //id
    "idInCart": 2409, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Kokosmilch 	0,25l Dose", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.60},
    {"id": 1210, //id
    "idInCart": 1210, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Tsingtaobier (chinesisches Bier) 	0,33 l", //name
    "description":"", //description
    "ingredients":" ",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.50},
    {"id": 1211, //id
    "idInCart": 1211, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Ottakringer Helles, 	Dose 0,5 l", //name
    "description":"", //description
    "ingredients":" ",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.00},
    {"id": 1212, //id
    "idInCart": 1212, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Pflaumenwein 0,75 l", //name
    "description":"", //description
    "ingredients":" ",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":7.90},
    {"id": 1213, //id
    "idInCart": 1213, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Lycheewein	 0,75 l", //name
    "description":"", //description
    "ingredients":" ",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":7.90},
    {"id": 1214, //id
    "idInCart": 1215, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Grüner Veltiner 1l", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.00},
    {"id": 1216, //id
    "idInCart": 1216, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Zweigelt 1l", //name
    "description":"", //description
    "ingredients":" ",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.00},
    {"id": 1217, //id
    "idInCart": 1217, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"", //articleCode
    "articleGroup":"getraenke", //articleGroup
    "name":"Red Bull Dose", //name
    "description":"", //description
    "ingredients":"",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":2.50}];
