import { Article } from './article.model';


export const spezialitaet: Article[] = [
 {"id": 1, //id
"idInCart": 1, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"A", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Acht Schätze", //name
"description":"", //description
"ingredients":"F, B, A", //ingredients
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":8.00},
{"id": 2, //id
"idInCart": 2, 
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"B", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Gong Bao Ji", //name
"description":"Hühnerfleisch, Zwiebeln, Paprika, Nüsse", //description
"ingredients":"E, F, A", //ingredients
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":8.10},
{"id": 3, //id
"idInCart": 3, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"E", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Yu Xiang Rou Si", //name
"description":"Schweinefleisch, Pilze, Paprika, Ingwer, Knoblauch)", //description
"ingredients":"F, A", //ingredients
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":8.10},
{"id": 4, //id
"idInCart": 4, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"F", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Ba Bao Ya", //name
"description":"Ente auf Acht Schätzen", //description
"ingredients":"A, F, B", //ingredients
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":11.70},
{"id": 5, //id
"idInCart": 5, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"G", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Da Xia Ya", //name
"description":"Ente auf Garnelen", //description
"ingredients":"B, F, A", //ingredients
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":15.20},
{"id": 6, //id
"idInCart": 6, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"H", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"La Zi Rou", //name
"description":"Schweinefleisch", //description
"ingredients":"F, A",
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":8.00},
{"id": 7, //id
"idInCart": 7, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"I",
"articleGroup":"spezialitaet", //articleGroup
"name":"Jiang Bao Ji Ding", //name
"description":"Huhnerfleisch", //description
"ingredients":"F, A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":8.10},
{"id": 8, //id
"idInCart": 8, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"J", //name
"articleGroup":"spezialitaet", //articleGroup
"name":"San Xian Ya",
"description":"Ente auf Hühnerfleisch, Schinken, Krabben", //description
"ingredients":"A, B, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":13.70},
{"id": 9, //id
"idInCart": 9, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"L",
"articleGroup":"spezialitaet", //articleGroup
"name":"Xiang Su Ya", //name
"description":"Knusprige halbe Ente mit Spezialsauce", //description
"ingredients":"A, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":9.80},
{"id": 10, //id
"idInCart": 10, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"M", //name
"articleGroup":"spezialitaet", //articleGroup
"name":"Xiang Su Ji", //name
"description":"Knusp. Hühnerbrust mit Spezialsauce", //description
"ingredients":"A, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":8.00},
{"id": 11, //id
"idInCart": 11, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"N", //name
"articleGroup":"spezialitaet", //articleGroup
"name":"Qing Chao Jie Cai", //name
"description":"Broccoli mit Knoblauch", //description
"ingredients":"F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":7.00},
{"id": 12, //id
"idInCart": 12, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"O", //name
"articleGroup":"spezialitaet", //articleGroup
"name":"Peking Ente fur eine Person",
"description":"", //description
"ingredients":"A, F, C",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":10.90},
{"id": 13, //id
"idInCart": 13, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"P", //name
"articleGroup":"spezialitaet", //articleGroup
"name":"Garnelen à la Gong Bao", //description
"description":"", //description
"ingredients":"scharf;B, E, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":14.40},
{"id": 14, //id
"idInCart": 14, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"Q", //name
"articleGroup":"beilagen", //articleGroup
"name":"Schätze der Natur",
"description":"Chinakohl mit Pilzen u. Broccoli", //description;;", //description
"ingredients":"F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":7.00}];

export const beilagen: Article[] = [
{"id": 100, //id
"idInCart": 100, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"", //articleCode
"articleGroup":"beilagen", //articleGroup
"name":"1 Portion Jasminreis", //name
"description":"", //description
"ingredients":"", //ingredients
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":1.20},
{"id": 101, //id
"idInCart": 101, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"", //articleCode
"articleGroup":"beilagen", //articleGroup
"name":"1 Portion gebratener Eierreis mit Gemüse", //name
"description":"", //description
"ingredients":"A, F", //ingredients
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.00},
{"id": 102, //id
"idInCart": 102, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"", //articleCode
"articleGroup":"beilagen", //articleGroup
"name":"1 Portion gebratene Nudeln mit Gemüse", //name
"description":"", //description
"ingredients":"A, F", //ingredients
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.00},
{"id": 103, //id
"idInCart": 103, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"", //articleCode
"articleGroup":"beilagen", //articleGroup
"name":"1 Portion gebratene Glasnudeln mit Gemüse", //name
"description":"", //description
"ingredients":"A, F", //ingredients
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.00},
{"id": 104, //id
"idInCart": 104, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"", //articleCode
"articleGroup":"beilagen", //articleGroup
"name":"1 Portion Ingwer-Salat", //name
"description":"", //description
"ingredients":"", //ingredients
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":1.20}];


export const suppen: Article[] = [
{"id": 300, //id
"idInCart": 300, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"1", //articleCode
"articleGroup":"suppen", //articleGroup
"name":"Huhn-Eierblumen-Suppe", //name
"description":"", //description
"ingredients":"C",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 301, //id
"idInCart": 301, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"2", //articleCode
"articleGroup":"suppen", //articleGroup
"name":"Meeresfruchtesuppe", //name
"description":"", //description
"ingredients":";C, B, A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.00},
{"id": 302, //id
"idInCart": 302, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"3", //articleCode
"articleGroup":"suppen", //articleGroup
"name":"Huhn-Pilze-Bambus-Suppe", //name
"description":"", //description
"ingredients":";F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 303, //id
"idInCart": 303, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"4", //articleCode
"articleGroup":"suppen", //articleGroup
"name":"Pikant-säuerliche Suppe", //name
"description":"", //description
"ingredients":";E, F, C",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 304, //id
"idInCart": 304, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"5", //articleCode
"articleGroup":"suppen", //articleGroup
"name":"Fleischklösschensuppe mit Glasnudeln", //name
"description":"", //description
"ingredients":";F, A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 305, //id
"idInCart": 305, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"6", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Wan-Tan Suppe", //name
"description":"", //description
"ingredients":";C, F, A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 306, //id
"idInCart": 3065, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"7", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Chinesische Tang Suppe m. Eierblumen", //name
"description":"", //description
"ingredients":";C",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 307, //id
"idInCart": 307, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"8", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Dou-Fu Gemuse Suppe", //name
"description":"", //description
"ingredients":";F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 308, //id
"idInCart": 308, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"9", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Senfgemusesuppe mit Schweinefleisch", //name
"description":"", //description
"ingredients":";A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 309, //id
"idInCart": 309, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"10", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Miso Suppe", //name
"description":"", //description
"ingredients":";A, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.50},
{"id": 5, //id
"idInCart": 310, //idInCart
"numberInCart":310, //numberInCart
"added": false,
"articleCode":"11", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Mais-Eier-Suppe", //name
"description":"", //description
"ingredients":";A, C",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.20},
{"id": 311, //id
"idInCart": 311, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"12", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Garnelen-Pilze-Bambus-Suppe", //name
"description":"", //description
"ingredients":";B, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.00},
{"id": 312, //id
"idInCart": 312, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"13", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Haifischflossensuppe", //name
"description":"", //description
"ingredients":";B, A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.20},
{"id": 313, //id
"idInCart": 313, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"14", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Fruhlingsrolle", //name
"description":"", //description
"ingredients":";A, F, C",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.00},
{"id": 314, //id
"idInCart": 314, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"15", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Hummer-Chips mit suss-saurer Sauce", //name
"description":"", //description
"ingredients":"A, B",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.70},
{"id": 315, //id
"idInCart": 315, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"16", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Gemischter Salat", //name
"description":"", //description
"ingredients":";F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.70},
{"id": 316, //id
"idInCart": 316, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"17", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Sojakeimling-Salat", //name
"description":"", //description
"ingredients":";F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":2.70},
{"id": 317, //id
"idInCart": 317, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"18", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Garnelen Butterfly-Art", //name
"description":"", //description
"ingredients":";A, B",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":5.10},
{"id": 318, //id
"idInCart": 318, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"19", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Krabben-Salat", //name
"description":"", //description
"ingredients":";A, B",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":4.10},
{"id": 319, //id
"idInCart": 319, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"20", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Garnelen Torpedo-Art", //name
"description":"", //description
"ingredients":";A, B",
"newArticle":false, 
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":5.10},
{"id": 320, //id
"idInCart": 320, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"21", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Age Dou-Fu mit Chicken Sauce", //name
"description":"", //description
"ingredients":";E, F, A",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.50},
{"id": 321, //id
"idInCart": 321, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"22", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Gebackene Huhnerflugel", //name
"description":"", //description
"ingredients":"A, E",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":4.10},
{"id": 322, //id
"idInCart": 322, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"23", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Gebackene Wan-Tan mit suss-saurer Sauce", //name
"description":"", //description
"ingredients":"E, A, F",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.30},
{"id": 323, //id
"idInCart": 323, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"24", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Gebackene Tintenfischringe in Chicken Sauce", //name
"description":"", //description
"ingredients":"A, E",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":5.10},
{"id": 324, //id
"idInCart": 324, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"25", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Minifrühlingsrollen mit süss-saurer Sauce", //name
"description":"", //description
"ingredients":"A, E",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.00},
{"id": 325, //id
"idInCart": 325, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"25a", //articleCode
"articleGroup":"spezialitaet", //articleGroup
"name":"Pommes Frites", //description
"description":"", //description
"ingredients":"",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":3.00}];


export const ente: Article[] = [
    {"id": 400, //id
    "idInCart": 400, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"26", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Knusprige Ente auf geratenen Nudeln", //description
    "description":"milde oder scharfe Sauce", //description
    "ingredients":"F, C, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.80 },
    {"id": 401, //id
    "idInCart": 401, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"27", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Knusprige Ente auf gebratenem Reis", //description
    "description":"milde oder scharfe Sauce", //description
    "ingredients":"F, C, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.80},
    {"id": 402, //id
    "idInCart": 402, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"28", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Knusprige Ente mit Pilzen und Bambus", //description
    "description":"", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.80},
    {"id": 403, //id
    "idInCart": 403, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"29", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Knusprige Ente mit Morcheln und Bambus", //description
    "description":"", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.80},
    {"id": 404, //id
    "idInCart": 404, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"30", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Knusprige Ente m. Paprika u. Zwiebeln", //description
    "description":";", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.80},
    {"id": 405, //id
    "idInCart": 405, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"31", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Knusprige Ente nach Sichuan-Art", //description
    "description":"", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":true, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":8.80},
    {"id": 406, //id
    "idInCart": 406, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"32", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Knusprige Ente mit Gemüse", //description
    "description":"", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.80},
    {"id": 407, //id
    "idInCart": 407, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"33", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Knusprige Ente mit Ananas süss-sauer", //description
    "description":"", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.80},
    {"id": 408, //id
    "idInCart": 408, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"34", //articleCode
    "articleGroup":"ente", //articleGroup
    "name":"Thai-Ente mit Kokosmilch, Basilikum", //description
    "description":"", //description
    "ingredients":"F, A, G",
    "newArticle":false,  
    "sharp":true, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":9.50}];

    export const fisch: Article[] = [
        {"id": 500, //id
        "idInCart": 500, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"38", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Thai-Pangasiusf. Kokosmilch u. Basilikum", //description
        "description":"", //description
        "ingredients":"D, A, G",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.90 },
        {"id": 501, //id
        "idInCart": 501, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"39", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Gebr. Pangasiusfilet mit gemischt. Gemüse", //description
        "description":"", //description
        "ingredients":"D, F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.00},
        {"id": 502, //id
        "idInCart": 502, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"40", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Gebratenes Pangasiusfilet mit Sojakeimlingen", //description
        "description":"", //description
        "ingredients":"D, F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.00},
        {"id": 503, //id
        "idInCart": 503, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"42", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Geback. Tintenfischringe mit süß-sauer Sauce", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":9.50}];

        export const schwein: Article[] = [
            {"id": 600, //id
            "idInCart": 600, //idInCart
            "numberInCart":0, //numberInCart
            "added": false,
            "articleCode":"45", //articleCode
            "articleGroup":"fisch", //articleGroup
            "name":"Schweinefleisch m. Pilzen u. Bambus", //description
            "description":"", //description
            "ingredients":"F, A",
            "newArticle":false,  
            "sharp":false, //sharp
            "vegetarian":false, //vegetarian
            "imageUrl":"", //imageUrl
            "optionsAvailable":false, //optioptionsAvailableonsAvailable
            "option":[], //option 
            "optionName":0, //option
            "price":7.50},
            {"id": 601, //id
            "idInCart": 601, //idInCart
            "numberInCart":0, //numberInCart
            "added": false,
            "articleCode":"", //articleCode
            "articleGroup":"fisch", //articleGroup
            "name":"Schweinefleisch mit Morcheln u. Bambus", //description
            "description":"", //description
            "ingredients":"F, A",
            "newArticle":false,  
            "sharp":false, //sharp
            "vegetarian":false, //vegetarian
            "imageUrl":"", //imageUrl
            "optionsAvailable":false, //optioptionsAvailableonsAvailable
            "option":[], //option 
            "optionName":0, //option
            "price":7.50},
        {"id": 602, //id
        "idInCart": 602, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"47", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Schweinefleisch mit Broccoli, Zwiebeln u. Porre", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.50},
        {"id":603, //id
        "idInCart": 603, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"48", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Schweinefleisch süss-sauer", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.00},
        {"id": 604, //id
        "idInCart": 604, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"49", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Schweinefleisch mit Zwiebeln", //description
        "description":"", //description
        "ingredients":"",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.00},
        {"id": 605, //id
        "idInCart": 605, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"51", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Schweinefleisch nach Sichuan-Art", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.50},
        {"id": 606, //id
        "idInCart": 606, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"53", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Schweinefleisch Sa-De ;mit Bambus, Apfel, Nüssen, suess-sauer-scharf", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.50},
        {"id": 607, //id
        "idInCart": 607, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"54", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Schweinefl. geback. in süss-sauer-scharfer Sauce", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.50},
        {"id": 608, //id
        "idInCart": 608, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"55", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Schweinefleisch mit Pilzen u. Champignons", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.50},
        {"id": 609, //id
        "idInCart": 609, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"56", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Thai-Schweinefl. Kokosmilch u. Basilikum", //description
        "description":"", //description
        "ingredients":"A, G",
        "newArticle":true,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.00},
        {"id": 610, //id
        "idInCart": 610, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"57", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Sa-Za Schweinefleisch", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.50}];

        export const huhn: Article[] = [
        {"id": 700, //id
        "idInCart": 700, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"60", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch mit Pilzen und Bambus", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 701, //id
        "idInCart": 701, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"61", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch m. Morcheln u. Bambus", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 702, //id
        "idInCart": 702, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"62", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch mit Broccoli, Zwiebeln und Porre", //description
        "description":"", //description
        "ingredients":"",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 703, //id
        "idInCart": 703, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"63", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch süss-sauer", //description
        "description":"", //description
        "ingredients":"",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 704, //id
        "idInCart": 704, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"64", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch mit Bambus, Apfel und Nüssen;süss-sauer-scharf", //description
        "description":"", //description
        "ingredients":"F, A, H",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 705, //id
        "idInCart": 705, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"65", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfl. mit Bambus, Champignons u. Nüssen", //description
        "description":"", //description
        "ingredients":"F, A, H",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 706, //id
        "idInCart": 706, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"66", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch nach Sichuan-Art", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 707, //id
        "idInCart": 707, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"67", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch mit verschiedenem Gemüse", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 708, //id
        "idInCart": 708, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"68", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch mit Ananas", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 709, //id
        "idInCart": 709, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"69", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Paniertes Hühnerfleisch in Wu-Xiang Sauce", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 710, //id
        "idInCart": 710, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"70", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch mit Pilzen u. Champignons", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 711, //id
        "idInCart": 711, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"71", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch mit Curry", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 712, //id
        "idInCart": 712, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"72", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Hühnerfleisch mit grünem Paprika", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 713, //id
        "idInCart": 713, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"73", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Knuspriges Hühnerfilet mit Mischgemüse", //description
        "description":"", //description
        "ingredients":"",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.00},
        {"id": 714, //id
        "idInCart": 714, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"74", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Knuspriges Hühnerfilet auf gebratenem Reis oder auf Nudeln mit Sauce;;scharf", //description
        "description":"", //description
        "ingredients":"F, A, C",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.50},
        {"id": 715, //id
        "idInCart": 715, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"74a", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Thai-Hühnerfl. Kokosmilch u. Basilikum;", //description
        "description":"", //description
        "ingredients":"F, A, G",
        "newArticle":true,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.50},
        {"id": 716, //id
        "idInCart": 716, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"74b", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Knusp. Hühnerfilet mit Thaicurry, Kokosmilch  und Basilikum", //description
            "description":"", //description
            "ingredients":"F, A, G",
            "newArticle":true,  
            "sharp":false, //sharp
            "vegetarian":false, //vegetarian
            "imageUrl":"", //imageUrl
            "optionsAvailable":false, //optioptionsAvailableonsAvailable
            "option":[], //option 
            "optionName":0, //option
            "price":8.50}];


            export const garnele: Article[] = [
            {"id": 800, //id
        "idInCart": 800, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"75", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Garnelen mit Pilzen und Bambus", //description
        "description":"", //description
        "ingredients":"B, A, F",
        "newArticle":false,  
        "sharp":false, //s;harp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":13.80},
        {"id": 801, //id
        "idInCart": 801, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"76", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Garnelen mit Morcheln und Bambus", //description
        "description":"", //description
        "ingredients":"B, A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":13.80},
        {"id": 802, //id
        "idInCart": 802, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"78", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Tempura Garnelen mit Chicken Sauce", //description
        "description":"", //description
        "ingredients":"B, A, F, C",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":15.20},
        {"id": 803, //id
        "idInCart": 803, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"79", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Garnelen nach Sichuan-Art", //description
        "description":"", //description
        "ingredients":"B, A, F",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":13.80},
        {"id": 804, //id
        "idInCart": 804, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"80", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Thai-Garnelen, Kokosmilch u. Basilikum neu", //description
        "description":"", //description
        "ingredients":"F, A, G",
        "newArticle":true,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":14.50}];

        export const rind: Article[] = [
        {"id": 805, //id
        "idInCart": 805, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"85", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch mit Pilzen und Bambus", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 806, //id
        "idInCart": 806, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"86", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch mit Morcheln und Bambus", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 807, //id
        "idInCart": 807, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"87", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch m. Broccoli, Zwiebeln, Porre", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.10},
        {"id": 808, //id
        "idInCart": 808, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"88", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch mit Zwiebeln", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 809, //id
        "idInCart": 809, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"89", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Sa-Za Rindfleisch", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 810, //id
        "idInCart": 810, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"90", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch mit Curry", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 811, //id
        "idInCart": 811, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"91", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch nach Sichuan-Art", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 812, //id
        "idInCart": 812, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"92", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch mit verschiedenem Gemüse", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 813, //id
        "idInCart": 813, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"93", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Thai-Rindfleisch, Kokosmilch u. Basilikum", //description
        "description":"", //description
        "ingredients":"A,F,G",
        "newArticle":true,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.90},
        {"id": 814, //id
        "idInCart": 814, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"94", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfl. gebacken in süss-sauer-scharfer Sauce", //description
        "description":"", //description
        "ingredients":"A, F, C",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 815, //id
        "idInCart": 815, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"96", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch m. Pilzen u. Champignons", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.10},
        {"id": 816, //id
        "idInCart": 816, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"97", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch mit Broccoli", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.90},
        {"id": 817, //id
        "idInCart": 817, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"99", //articleCode
        "articleGroup":"fisch", //articleGroup
        "name":"Rindfleisch in Knoblauch, Ingwer, Scharfbohnensauce", //description
        "description":"", //description
        "ingredients":"A, F",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.10}];

        export const reisnudeln: Article[] = [
        {"id": 900, //id
        "idInCart": 900, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"105", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratener Eierreis mit Hühnerfleisch", //description
        "description":"", //description
        "ingredients":"C, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":6.90},
        {"id": 901, //id
        "idInCart": 901, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"106", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebrat. Eierreis mit Huhn, Schinken u. Rind", //description
        "description":"", //description
        "ingredients":"C, F ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 902, //id
        "idInCart": 902, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"107", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratener Eierreis mit Schinken", //description
        "description":"", //description
        "ingredients":"C, F",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":6.90},
        {"id": 903, //id
        "idInCart": 903, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"108", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Nasi Goreng ", //description
        "description":"Gebratener Eierreis mit Hühnerfleisch und Curry", //description
        "ingredients":"C, F, H",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 904, //id
        "idInCart": 904, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"109", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebrat. Eierreis m. Ketchup und 8 Kostbarkeiten", //description
        "description":"", //description
        "ingredients":"C, F, B",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 905, //id
        "idInCart": 905, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"110", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratener Eierreis mit Krabben", //description
        "description":"", //description
        "ingredients":"C, F, B",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.00},
        {"id": 906, //id
        "idInCart": 906, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"112", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratener Eierreis mit Garnelen", //description
        "description":"", //description
        "ingredients":"C, F, B ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":11.50},
        {"id": 907, //id
        "idInCart": 907, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"116", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratene Nudeln mit Hühnerfleisch", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":6.90},
        {"id": 908, //id
        "idInCart": 908, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"117", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratene Nudeln mit Huhn, Schinken und Rind", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 909, //id
        "idInCart": 909, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"118", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebrat. Nudeln mit Curry und Hühnerfleisch", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":6.90},
        {"id": 910, //id
        "idInCart": 910, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"119", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratene Nudeln mit Krabben u. Gemüse", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.00},
        {"id": 911, //id
        "idInCart": 911, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"120", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebrat. Nudeln mit Ketchup und 8 Kostbarkeiten", //description
        "description":"", //description
        "ingredients":"F, A, B",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.30},
        {"id": 912, //id
        "idInCart": 912, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"121", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Scharfe Nudeln mit Huhn, Schinken und Rind", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.30},
        {"id": 913, //id
        "idInCart": 913, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"124", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratene Nudeln mit Garnelen und Gemüse", //description
        "description":"", //description
        "ingredients":"F, A, B",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":11.50},
        {"id": 914, //id
        "idInCart": 914, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"135", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratene Reisnudeln mit Hühnerfleisch", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":6.80},
        {"id": 914, //id
        "idInCart": 914, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"136", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebrat. Reisnudeln mit Huhn, Schinken und Rind", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.20},
        {"id": 915, //id
        "idInCart": 915, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"137", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratene Reisnudeln mit Krabben", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.20},
        {"id": 916, //id
        "idInCart": 916, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"138", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebratene Glasnudeln mit Krabben", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.20},
        {"id": 917, //id
        "idInCart": 917, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"139", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"San Xian gebratene Glasnudeln", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.20},
        {"id": 918, //id
        "idInCart": 918, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"140", //articleCode
        "articleGroup":"reisnudeln", //articleGroup
        "name":"Gebrat. Reisnudeln mit Garnelen und Gemüse", //description
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":12.20}];


export const gemischteteller: Article[] = [
        {"id": 1000, //id
        "idInCart": 1000, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"L1", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":" Lady's Teller mit Suppe und Nachtisch", //description
        "description":"2 Stk. California Maki, 3 Stk. Kappa Maki, 1 Temaki, Ikura-Zushi (Kaviar), 2 Stk. Lachs Sushi, 2 Stk. Thunfisch Sushi, 2 Stk. Butterfisch Sushi", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":13.50},
        {"id": 1001, //id
        "idInCart": 1001, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"L2", //articleCode
        "articleGroup":"gemischteteller", //articleGroup
        "name":"L2 	Gentleman's Teller mit Suppe und Nachtisch", //description
        "description":"2 Stk. Futo Maki, 3 Stk. Lachs Maki, Ikura-Zushi (Kaviar), 2 Stk. Lachs Sushi, 2 Stk. Thunfisch Sushi, 2 Stk. Ebi Sushi, 3 Stk. Kappa Maki", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":13.50}, 
        {"id": 1002, //id
    "idInCart": 1002, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"L3", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"3 Avocado-Maki, 3 Futo-Maki, 2 Lachs-Maki, 2 Garnelen-Sushi, 1 Temaki, 2 Lachs-Sushi", //description
    "description":"", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":10.90},
    {"id": 1003, //id
    "idInCart": 1003, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"L4", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"3 California-Maki, 2 Lachs-Sushi, 2 Lachs-Maki, 2 Butterfisch-Sushi,1 Thunfisch-Sushi, 4 Kappa-Maki", //description
    "description":"", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":10.90}];
        

        export const doufu: Article[] = [
            {"id": 1500, //id
            "idInCart": 1500, //idInCart
            "numberInCart":0, //numberInCart
            "added": false,
            "articleCode":"130", //articleCode
        "articleGroup":"doufu", //articleGroup
        "name":"Thai-Dou-Fu Kokosmilch u. Basilikum",
        "description":"", //description
        "ingredients":"F, A, G ",
        "newArticle":true,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.00},
        {"id": 1501, //id
        "idInCart": 1501, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"131", //articleCode
        "articleGroup":"doufu", //articleGroup
        "name":"Hong Shao Dou-Fu mit Hühnerfleisch und Gemüse",
        "description":"", //description
        "ingredients":"F, A, G ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.00},
        {"id": 1502, //id
"idInCart": 1502, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"132", //articleCode
        "articleGroup":"doufu", //articleGroup
        "name":"Ma Po Dou-Fu mit Schweinefleisch und Gemüse",
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.30},
        {"id": 1503, //id
"idInCart": 1503, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"133", //articleCode
        "articleGroup":"doufu", //articleGroup
        "name":"Gebackener Dou-Fu mit Schweinefleisch, Morcheln und Broccoli",
        "description":"", //description
        "ingredients":"F, A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.30}];

        export const gemuese: Article[] = [
            {"id": 1504, //id
            "idInCart": 1504, //idInCart
            "numberInCart":0, //numberInCart
            "added": false,
            "articleCode":"146", //articleCode
        "articleGroup":"gemuese", //articleGroup
        "name":"Gebratene Pilze mit Bambus und Broccoli",
        "description":"Kokosmilch u. Basilikum neu", //description
        "ingredients":"F, A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":6.50},
        {"id": 1505, //id
        "idInCart": 1505, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"147", //articleCode
    "articleGroup":"gemuese", //articleGroup
    "name":"Gebratener Bambus mit Dou-Fu und Broccoli",
    "description":"", //description
    "ingredients":"F, A ",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":6.50},
    {"id": 1506, //id
    "idInCart": 1506, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"148", //articleCode
"articleGroup":"gemuese", //articleGroup
"name":"Fastenspeise der Buddhisten",
"description":"", //description
"ingredients":"F, A ",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":6.50},
{"id": 1507, //id
"idInCart": 1507, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"149", //articleCode
"articleGroup":"gemuese", //articleGroup
"name":"Gebratene Sojakeimlinge",
"description":"", //description
"ingredients":"F, A ",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":6.00},
{"id": 1508, //id
"idInCart": 1508, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"150", //articleCode
"articleGroup":"gemuese", //articleGroup
"name":"Gebratene Nudeln mit Ei und Gemüse",
"description":"", //description
"ingredients":"F, A ",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":6.00},
{"id": 1509, //id
"idInCart": 1509, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"151", //articleCode
"articleGroup":"gemuese", //articleGroup
"name":"Gebratener Eierreis mit Sojakeimlingen",
"description":"", //description
"ingredients":"F, A ",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":6.50},
{"id": 1510, //id
"idInCart": 1510, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"152", //articleCode
"articleGroup":"gemuese", //articleGroup
"name":"Gebratene Reisnudeln mit Ei und Gemüse",
"description":"", //description
"ingredients":"F, A ",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":6.50},
{"id": 1511, //id
"idInCart": 1511, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"B4", //articleCode
"articleGroup":"gemuese", //articleGroup
"name":"Knusprig paniertes Gemüse, Sauce nach Wahl",
"description":"", //description
"ingredients":"F, A ",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option 
"optionName":0, //option
"price":8.00}];
        export const lamm: Article[] = [
            {"id": 1512, //id
            "idInCart": 503, //idInCart
            "numberInCart":0, //numberInCart
            "added": false,
            "articleCode":"F1", //articleCode
        "articleGroup":"lamm", //articleGroup
        "name":"Cong Bao Lammfleisch (mit Zwiebeln, Paprika, Porre,Ingwer, Knoblauch) ",
        "description":"", //description
        "ingredients":"F, A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":11.90},
            {"id": 503, //id
    "idInCart": 1512, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"F2", //articleCode
        "articleGroup":"lamm", //articleGroup
        "name":"Lammfleisch nach Sichuan-Art",
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":11.90},
        {"id": 1513, //id
"idInCart": 1513, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"F3", //articleCode
        "articleGroup":"lamm", //articleGroup
        "name":"Lammfleisch nach Peking-Art ",
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":11.90},
        {"id": 1514, //id
"idInCart": 1514, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"F4", //articleCode
        "articleGroup":"lamm", //articleGroup
        "name":"Sa-Za Lammfleisch",
        "description":"", //description
        "ingredients":"F, A",
        "newArticle":false,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":11.90},
        {"id": 1515, //id
"idInCart": 1515, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"F5", //articleCode
        "articleGroup":"lamm", //articleGroup
        "name":"Thai Lammfleisch mit Kokosmilch u. Basilikum",
        "description":"Kokosmilch u. Basilikum neu", //description
        "ingredients":"F, A",
        "newArticle":true,  
        "sharp":true, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":12.50}];

        export const tepan: Article[] = [
            {"id": 1516, //id
            "idInCart": 1516, //idInCart
            "numberInCart":0, //numberInCart
            "added": false,
            "articleCode":"T1", //articleCode
        "articleGroup":"tepan", //articleGroup
        "name":"Tepan Garnelen auf Tepangemüse, Jasminreis",
        "description":"", //description
        "ingredients":"B, A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":14.50},
        {"id": 503, //id
"idInCart": 1517, //idInCart
"numberInCart":1517, //numberInCart
"added": false,
"articleCode":"T2", //articleCode
        "articleGroup":"tepan", //articleGroup
        "name":"Tepan Garnelenspieße mit Jasminreis  ",
        "description":"", //description
        "ingredients":"B, A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":14.50},
        {"id": 503, //id
"idInCart": 1518, //idInCart
"numberInCart":1518, //numberInCart
"added": false,
"articleCode":"T3", //articleCode
        "articleGroup":"tepan", //articleGroup
        "name":"Tepan Hühnerfleischspieße mit Jasminreis",
        "description":"", //description
        "ingredients":"A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.00},
        {"id": 1519, //id
        "idInCart": 1519, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"T4", //articleCode
        "articleGroup":"tepan", //articleGroup
        "name":"Misoyaki, eingelegter Butterfisch in Miso-Sauce, Jasminreis",
        "description":"", //description
        "ingredients":"D, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":10.90},
        {"id": 1520, //id
"idInCart": 1520, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"T5", //articleCode
        "articleGroup":"tepan", //articleGroup
        "name":"Lachs Teriyaki mit Gemüse und Jasminreis  ",
        "description":"", //description
        "ingredients":"D, A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option
        "optionName":0, //option
        "price":10.90},
        {"id": 1521, //id
"idInCart": 1521, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"T6", //articleCode
        "articleGroup":"tepan", //articleGroup
        "name":"Tori Teriyaki, gegrillte Hühnerkeule mit Gemüse und Jasminreis  ",
        "description":"", //description
        "ingredients":"A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":10.90},
        {"id": 1522, //id
"idInCart": 1522, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"T7", //articleCode
        "articleGroup":"tepan", //articleGroup
        "name":"Maguro Teriyaki, gegrillter Thunfisch mit Jasminreis  ",
        "description":"", //description
        "ingredients":"D, A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":10.90}];
        
        
        
        
        export const nachtisch: Article[] = [
            {"id": 1600, //id
            "idInCart": 1600, //idInCart
            "numberInCart":0, //numberInCart
            "added": false,
            "articleCode":"160", //articleCode
        "articleGroup":"nachtisch", //articleGroup
        "name":"Gebackene Bananen mit Honig ",
        "description":"Kokosmilch u. Basilikum neu", //description
        "ingredients":" A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":2.60},
        {"id": 1601, //id
"idInCart": 1601, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"161", //articleCode
        "articleGroup":"nachtisch", //articleGroup
        "name":"Gebackene Apfelspalten mit Honig ",
        "description":"Kokosmilch u. Basilikum neu", //description
        "ingredients":" A",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":2.60},
        {"id": 1602, //id
"idInCart": 1602, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"162", //articleCode
        "articleGroup":"nachtisch", //articleGroup
        "name":"Gebackene Ananas mit Honig",
        "description":"", //description
        "ingredients":" A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":2.60},
        {"id": 1603, //id
"idInCart": 1603, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"163", //articleCode
        "articleGroup":"nachtisch", //articleGroup
        "name":"Gebackene Früchte mit Honig",
        "description":"", //description
        "ingredients":" A ",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":3.00},
        {"id": 1604, //id
"idInCart": 1604, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"165", //articleCode
        "articleGroup":"nachtisch", //articleGroup
        "name":"Lychee-Kompott ",
        "description":"", //description
        "ingredients":"",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":2.60},
        {"id": 1605, //id
"idInCart": 1605, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"166", //articleCode
        "articleGroup":"nachtisch", //articleGroup
        "name":"Früchte-Cocktail ",
        "description":"", //description
        "ingredients":"",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":3.00}];

        export const udon: Article[] = [
            {"id": 1606, //id
            "idInCart": 1606, //idInCart
            "numberInCart":0, //numberInCart
            "added": false,
            "articleCode":"J30", //articleCode
            "articleGroup":"udon", //articleGroup
            "name":"Gemüse Udonsuppe oder Ramen", //description
            "description":"", //description
            "ingredients":"F, A",
            "newArticle":false,  
            "sharp":false, //sharp
            "vegetarian":false, //vegetarian
            "imageUrl":"", //imageUrl
            "optionsAvailable":false, //optioptionsAvailableonsAvailable
            "option":[], //option 
            "optionName":0, //option
            "price":7.00},
            {"id": 1607, //id
    "idInCart": 1607, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"J31", //articleCode
    "articleGroup":"udon", //articleGroup
    "name":"Meeresfrüchte Udonsuppe od. Ramen", //description
    "description":"scharf oder mild", //description
    "ingredients":"F, A",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":8.00},
        {"id": 1608, //id
        "idInCart": 1608, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J32", //articleCode
        "articleGroup":"udon", //articleGroup
        "name":"Yaki Udon", //description
        "description":"gebr. Udonnudeln mit Meeresfrüchten", //description
        "ingredients":"F,A,B,D",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":8.60},
        {"id": 1609, //id
        "idInCart": 1609, //idInCart
        "numberInCart":0, //numberInCart
        "added": false,
        "articleCode":"J33", //articleCode
        "articleGroup":"udon", //articleGroup
        "name":"Yoki Yaki Udon", //description
        "description":"gebr. Udonnudeln mit Hühnerfleisch", //description
        "ingredients":"F, A,B",
        "newArticle":false,  
        "sharp":false, //sharp
        "vegetarian":false, //vegetarian
        "imageUrl":"", //imageUrl
        "optionsAvailable":false, //optioptionsAvailableonsAvailable
        "option":[], //option 
        "optionName":0, //option
        "price":7.50}];

  