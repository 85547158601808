import { Article } from './article.model';
export const gemischteteller: Article[] = [
    {"id": 2300, //id
    "idInCart": 2300, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"L1", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Lady's Teller mit Suppe und Nachtisch", //name
    "description":"2 Stk. California Maki, 3 Stk. Kappa Maki, 1 Temaki, Ikura-Zushi (Kaviar), 2 Stk. Lachs Sushi, 2 Stk. Thunfisch Sushi, 2 Stk. Butterfisch Sushi", //description
    "ingredients":"B, D, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":13.50},
    {"id": 2301, //id
    "idInCart": 2301, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"L2", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"Gentlemans Teller mit Suppe und Nachtisch", //name
    "description":"2 Stk. Futo Maki, 3 Stk. Lachs Maki, Ikura-Zushi (Kaviar), 2 Stk. Lachs Sushi, 2 Stk. Thunfisch Sushi, 2 Stk. Ebi Sushi, 3 Stk. Kappa Maki", //description
    "ingredients":"B, D, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":13.50},
    {"id": 2302, //id
    "idInCart": 2302, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"L3", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"L3", //name
    "description":"3 Avocado-Maki, 3 Futo-Maki, 2 Lachs-Maki, 2 Garnelen-Sushi,1 Temaki, 2 Lachs-Sushi", //description
    "ingredients":"B, D, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":10.90},
    {"id": 2303, //id
    "idInCart": 2303, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"L4", //articleCode
    "articleGroup":"gemischteteller", //articleGroup
    "name":"L4", //name
    "description":"3 California-Maki, 2 Lachs-Sushi, 2 Lachs-Maki, 2 Butterfisch-Sushi, 1 Thunfisch-Sushi, 4 Kappa-Maki", //description
    "ingredients":"B, D, N",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option 
    "optionName":0, //option
    "price":10.90}];