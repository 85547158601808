import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent  {
  private _albums  = [];
  constructor(private _lightbox: Lightbox) {
    for (let i = 0; i <= 12; ++i) {
      const src = 'assets/img/galerie/' + i + '.jpg';
      const caption = '';
      const thumb = 'assets/img/galerie/p' + i + '.jpg';
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };
 
      this._albums.push(album);
    }
  }
 
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
