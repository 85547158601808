import { Injectable } from '@angular/core';
import { Article } from '../article/article.model'; 
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CartService {
  private cart = {"amountItemsOrder":0,"sumOrder":0,"articles":[]};
  constructor() {}
  private cartSubject = new BehaviorSubject<any>({loaded: true, cart: this.cart});
  CartState = this.cartSubject.asObservable();



 addArticle(article) {

  console.log("inCart:" +JSON.stringify(article));
  var index = this.cart.articles.findIndex(it => {return it.idInCart === article.idInCart});
  this.cart.amountItemsOrder = this.cart.amountItemsOrder + 1;
  this.cart.sumOrder = this.cart.sumOrder + article.price;
  if (index === -1) {
    if(article.numberInCart == 0) {
      article.numberInCart = 1;

    }
     //console.log("this._articles[index].numberInCart:" + this._articles[index].numberInCart);
      this.cart.articles.push(article);
     // console.log('in service 2: ' + JSON.stringify(this.cart.articles));
      
  } else {
    this.cart.articles[index].numberInCart  = this.cart.articles[index].numberInCart + 1;
   // console.log("this._articles[index].numberInCart" + this._articles[index].numberInCart);
  }

  this.cartSubject.next({loaded: true, cart: this.cart});

}

removeArticle(article) {

  //console.log('in service: ' + JSON.stringify(article));
  var index = this.cart.articles.findIndex(it => {console.log(it.idInCart);return it.idInCart === article.idInCart});
 // console.log("index" + index);
  if (index !== -1 && this.cart.articles[index].numberInCart == 1) {
    this.cart.articles.splice(index,1);
    this.cart.sumOrder = this.cart.sumOrder - article.price;
  } else {
    this.cart.articles[index].numberInCart  = this.cart.articles[index].numberInCart - 1;
    this.cart.sumOrder = this.cart.sumOrder - article.price;
  //  console.log("this._articles[index].numberInCart" + this._articles[index].numberInCart);
  }
  //this.cartSubject.next(<CartState>{_articles:  this._articles});
  this.cart.amountItemsOrder = this.cart.amountItemsOrder - 1;
  //this.sumOrder = this.sumOrder - _article.price;
  this.cartSubject.next({loaded: false, cart: this.cart});
}

deleteCart() {

  //console.log('in service: ' + JSON.stringify(article));
  this.cart.amountItemsOrder = 0;
  this.cart.sumOrder =0;
  this.cart.articles = [];
  this.cartSubject.next({loaded: true, cart: this.cart});
}





}
