import { Article } from './article.model';
export const spezial1Person: Article[] = [
    {"id": 1300, //id
    "idInCart": 1300, //idInCart
    "numberInCart":0, //numberInCart
    "added": false,
    "articleCode":"M1", //articleCode
    "articleGroup":"spezial1Person", //articleGroup
    "name":"Huhn Eierblumen Suppe,Hühnerfleisch mit Champignons, Schweinefleisch süß-sauer, Früchtecocktail", //description
    "description":"", //description
    "ingredients":"A, F, C",
    "newArticle":false,  
    "sharp":false, //sharp
    "vegetarian":false, //vegetarian
    "imageUrl":"", //imageUrl
    "optionsAvailable":false, //optioptionsAvailableonsAvailable
    "option":[], //option
    "optionName":0, //option
    "price":9.90},
 {"id": 1301, //id
"idInCart": 1301, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"M2", //articleCode
"articleGroup":"spezial1Person", //articleGroup
"name":"Pikant säuerliche Suppe, Frühlingsrolle, Hühnerfleisch mit Broccoli und Porre, Schweinefleisch nach Sichuan-Art, Früchtecocktail",
"description":"", //description
"ingredients":"A, F, C",
"newArticle":false,  
"sharp":true, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option
"optionName":0, //option
"price":10.90},
{"id": 1302, //id
"idInCart": 1302, //idInCart
"numberInCart":0, //numberInCart
"added": false,
"articleCode":"M3", //articleCode
"articleGroup":"spezial1Person", //articleGroup
"name":"Pikant säuerliche Suppe, Frühlingsrolle, Schweinefleisch mit Broccoli, Zwiebel gebraten, Hühnerfleisch gebacken mit Wu-Xiang Gemüsesauce, Frühlingscocktail",
"description":"", //description
"ingredients":"A, F, C",
"newArticle":false,  
"sharp":false, //sharp
"vegetarian":false, //vegetarian
"imageUrl":"", //imageUrl
"optionsAvailable":false, //optioptionsAvailableonsAvailable
"option":[], //option
"optionName":0, //option
"price":10.90}];

