import { Component, OnInit, Input } from '@angular/core';
import { Article } from '../article.model';
import { spezialitaet, beilagen,suppen,ente,fisch, schwein, huhn,garnele,udon, nachtisch,rind,lamm,gemuese,tepan,doufu,   reisnudeln } from '../article.mock';
import { getraenke } from '../getraenke';
import { gemischteteller } from '../gemischteteller';
import { maki } from '../maki';
import { sushi } from '../sushi';
import { bento } from '../bento';
import { spezial1Person } from '../spezial1Person';
import { spezial2Personen } from '../spezial2Personen';
import { mittagsmenue } from '../mittagsmenue';
//import { Cart2Service } from '../../cart/cart2.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
export class ArticleListComponent implements OnInit {


  getraenkeList = getraenke;
  gemischtetellerList = gemischteteller;
  sushiList = sushi;
  makiList = maki;
  spezialitaetenList = spezialitaet;
  beilagenList = beilagen;
  suppenList = suppen;
  enteList = ente;
  fischList = fisch;
  schweinList = schwein;
  huhnList = huhn;
  bentoList = bento;
  reisnudelnList = reisnudeln;
  rindList = rind;
  garneleList = garnele;
  mittagsmenueList = mittagsmenue;
  udonList = udon;
  tepanList = tepan;
  gemueseList = gemuese;
  lammList = lamm;
  doufuList = doufu;
  nachtischList = nachtisch;
  spezial1PersonList = spezial1Person;
  spezial2PersonenList = spezial2Personen;


  constructor( /*private _cartService : Cart2Service*/ ) {
   
  } 

  ngOnInit() {

  }
}


