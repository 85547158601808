import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buffet',
  templateUrl: './buffet.component.html',
  styleUrls: ['./buffet.component.css']
})
export class BuffetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
